import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Helen from './components/Helen';
import TargetPhoto from './components/TargetPhoto';
import PayPalLayoff from './components/PayPalLayoff';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/helen" element={<Helen />} />
        <Route path="/targetphoto" element = {<TargetPhoto />} />
        <Route path="/where-to-after-paypal" element={<PayPalLayoff />} />
      </Routes>
    </Router>
  );
}

export default App;